import * as THREE from "three";
import { SphericalController } from "@masatomakino/threejs-spherical-controls";


let container = document.getElementById("threejs-globe");
let destinationVec3;

let radius;
let cameraController

// ------------------------------------------------
// SETUP LOCATIONS
// ------------------------------------------------
const locations = [];

const cityElems = document.getElementsByClassName("vizy__globe__city");
[].forEach.call(cityElems, function (element) {
    locations.push({
      id: element.id,
      lat: parseFloat(element.getAttribute("data-latitude")),
      lon: parseFloat(element.getAttribute("data-longitude")),
    });
});

function latLonToVector(lat, lon, radius) {
  var spherical = {
    lat: THREE.MathUtils.degToRad(90 - lat),
    lon: THREE.MathUtils.degToRad(lon),
  };

  var vector = new THREE.Vector3().setFromSphericalCoords(
    radius,
    spherical.lat,
    spherical.lon
  );

  return vector;
}


export function goToTarget(target) {
  let { lat, lon } = locations.find(({ id }) => id === target);
  let point = latLonToVector(lat, lon, radius * 2);
  var altitude = 0;

  var coeff = 1 + altitude / radius;

  // const vec3target = new THREE.Vector3(point.x * coeff, point.y * coeff, point.z * coeff);
  destinationVec3 = new THREE.Vector3(
    point.x * coeff,
    point.y * coeff,
    point.z * coeff
  );

  cameraController.move(
    new THREE.Spherical().setFromVector3(destinationVec3)
  );

  // camera.position.set(vec3target.x, vec3target.y, vec3target.z);
  // camera.position.lerp(vec3target, 0.05);
}



export default function setupGlobe() {
  if (container) {
    const computedStyle = container.getBoundingClientRect();
    const { width, height } = computedStyle;
    // ------------------------------------------------
    // BASIC SETUP
    // ------------------------------------------------

    // Create an empty scene
    var scene = new THREE.Scene();

    // Create a basic perspective camera
    var camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    camera.position.z = 4;

    // Create a renderer with Antialiasing
    var renderer = new THREE.WebGLRenderer({ antialias: true });

    // Configure renderer clear color
    renderer.setClearColor("#000000");

    // Configure renderer size
    renderer.setSize(width, height);

    // Append Renderer to DOM
    container.appendChild(renderer.domElement);

    // ------------------------------------------------
    // FUN STARTS HERE
    // ------------------------------------------------

    radius = 2;
    // create globe
    const globe = new THREE.Mesh(
      new THREE.SphereGeometry(radius, 32, 32),
      new THREE.MeshBasicMaterial({
        map: new THREE.TextureLoader().load(
          "/dist/images/globe/ihrb-globe-wrap-texture-3.jpg"
        ),
        // bumpMap: THREE.ImageUtils.loadTexture('images/elev_bgifump_4k.jpg'),
        // bumpScale:   0.005,
        // specularMap: THREE.ImageUtils.loadTexture('images/water_4k.png'),
        // specular: new THREE.Color('grey')
      })
    );
    globe.geometry.rotateY(-Math.PI * 0.5);

    // add globe
    scene.add(globe);

    cameraController = new SphericalController(camera, globe);
    cameraController.initCameraPosition(
      new THREE.Spherical(4, 0, 0) // => North Pole
    );

    function addDot(lat, lon) {
      const vector = latLonToVector(lat, lon, radius);

      const geometry = new THREE.SphereGeometry(0.03, 32);
      const material = new THREE.MeshBasicMaterial({ color: 0xffffff });
      const circle = new THREE.Mesh(geometry, material);
      circle.position.set(vector.x, vector.y, vector.z);
      circle.rotation.x = Math.PI / 0.1;
      scene.add(circle);
    }

    locations.forEach(({ lat, lon }) => {
      addDot(lat, lon);
    });

    // Render Loop
    (function render() {
      requestAnimationFrame(render);

      // Render the scene
      renderer.render(scene, camera);
    })();

    // spin to the first location
    goToTarget(locations[0].id);


    function onWindowResize() {
      camera.aspect = container.clientWidth / container.clientHeight;

      camera.updateProjectionMatrix();

      renderer.setSize(container.clientWidth, container.clientHeight);
    }

    window.addEventListener("resize", onWindowResize, false);

  }
}
