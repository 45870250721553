export default function() {
  var els = document.getElementsByClassName("arrow-down-close");
  [].forEach.call(els, function (element) {

    element.addEventListener('click', (e) => {
      e.preventDefault();
      element.closest(".vizy__accordion-item").classList.toggle('vizy__accordion-item--open');
      element.classList.toggle('open');
    });
  });
}