import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function() {
  const pinElements = document.querySelectorAll('.section__pin');
  
  // create timelines
  [].forEach.call(pinElements, function (element) {

    ScrollTrigger.create({
      trigger: element,
      pin: true,
      start: 'top top',
      scrub: 0.5,
      endTrigger: ".section__wrapper",
      // end: "bottom bottom",
      end: 'bottom+=80px bottom',
      pinSpacing: false,
    });
  });
}