import gsap from 'gsap';
import scroll from './scroll';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

// Lazy-loading images is good for page load but bad for scroll triggers
// https://gsap.com/community/forums/topic/36860-loadinglazy-and-scrolltriggerrefresh/?do=findComment&comment=184111&_rid=119542
// Usage: you can optionally set lazy to false to change all images to load="eager". timeout is how many seconds it throttles the loading events that call ScrollTrigger.refresh()
function handleLazyLoad(config={}) {
  let lazyImages = gsap.utils.toArray("img[loading='lazy']"),
      timeout = gsap.delayedCall(config.timeout || 1, ScrollTrigger.refresh).pause(),
      lazyMode = config.lazy !== false,
      imgLoaded = lazyImages.length,
      onImgLoad = () => lazyMode ? timeout.restart(true) : --imgLoaded || ScrollTrigger.refresh();
  lazyImages.forEach((img, i) => {
    lazyMode || (img.loading = "eager");
    img.naturalWidth ? onImgLoad() : img.addEventListener("load", onImgLoad);
  });
}

function handleLink(e) {
  const link = e.target.closest('a');
  if (!link) return;
  const href = link.getAttribute('href');
  if (!href.length) return;
  e.preventDefault();
  scroll.scrollToEl(href);
  history.pushState({hash: href}, null, link.href);
}

export default function() {
  const masthead = document.querySelector('.masthead');
  const subnav = document.querySelector('.nav__subnav');
  if(!subnav) return;

  handleLazyLoad({ lazy: true, timeout: 1 });

  // setup active toggle
  const filter = document.querySelector('.nav__subnav__filter');
  if (filter) {
    filter.addEventListener('click', () => {
      filter.classList.toggle('nav__subnav__filter--active');
      document.querySelector('.nav__subnav ul').classList.toggle('nav__subnav--active');
    });
  }

  // get all subnav links
  const subnavLinks = gsap.utils.toArray(document.querySelectorAll('.nav__subnav a'));
  let scrollSections;
  scrollSections = subnavLinks.map(link => document.querySelector(link.getAttribute('href')));

  scrollSections.forEach((section, index) => {
    const link = subnavLinks.find(link => link.getAttribute('href') === `#${section.id}`);
    const start = `top top+=${masthead.offsetHeight}`;
    const end = `top top+=${masthead.offsetHeight}`;
    const footer = document.querySelector('footer');

    ScrollTrigger.create({
      trigger: section,
      start: start,
      endTrigger: scrollSections[index + 1] ? scrollSections[index + 1] : footer,
      end: end,
      onToggle(self) {
        if (self.isActive) {
          link.classList.add('nav__subnav__link--active');
        } else {
          link.classList.remove('nav__subnav__link--active');
        }
      }
    })
  });

  subnavLinks.forEach(link => link.addEventListener('click', handleLink));
}