// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html

if (history.scrollRestoration) {
  history.scrollRestoration = "manual";
}

import accordion from './modules/accordion';
import globe from './modules/globe';
import nav from './modules/nav';
import subnav from './modules/subnav';
import carousel from './modules/carousel';
import filter from './modules/filter';
import sticky from './modules/sticky';
import statistics from './modules/statistics';
import pin from './modules/pin';
import scroll from './modules/scroll';

accordion();
nav();
globe();
carousel();
filter();
sticky();
statistics();
pin();

const rootEL = document.documentElement;
rootEL.classList.remove('no-js');

window.addEventListener('load', function(e) {
  document.body.classList.remove('page-is-loading');
  subnav();
  scroll.init();
});

