import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

function scrollToEl(id, delay = 0) {
  gsap.to(window, {
    scrollTo: {
      y: id,
      offsetY() {
        const masthead = document.querySelector('.masthead');
        // -1 to make sure scrolltrigger threshold is reached if scrolling to a section that has a subnav link
        if (!masthead) return 0;
        const mastheadHeight = masthead.clientHeight - 1;
        return mastheadHeight;
      },
    },
    onUpdate() {
      ScrollTrigger.refresh();
    },
    // Seem to need short delay for the scrollTo position to be accurate
    delay: delay,
    duration: 0.5,
  })
}

function init() {
  if (!location.hash) return;
  history.replaceState({hash: location.hash}, null, location.url);
  // Seem to need short delay for the scrollTo position to be accurate
  scrollToEl(location.hash, 0.1);
}

const scroll = {
  scrollToEl: scrollToEl,
  init: init
}

export default scroll;