import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default function() {
  const stickyElements = document.querySelectorAll('.vizy--sticky');
  
  // create timelines
  [].forEach.call(stickyElements, function (element) {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: element,
        start: '-160px top',
        end: 'bottom bottom',
        scrub: true,
      }
    });

    tl.to(element, {
      position: 'sticky',
      top: 80,
    })
  });

  ScrollTrigger.refresh();
}