import Glide from '@glidejs/glide'

const itemsDOM = [];
const filters = document.querySelectorAll(".vizy__recommendations__topic input");
const filterIds = [];
const countElem = document.getElementById("recommendations-count");
const slideWrapper = document.getElementById("recommendation-glide-slides");

let recommendationsSlider;

function createSlider() {
  // create slider
  recommendationsSlider = new Glide('.recommendation-slider', {
    type: 'slider',
    autoplay: 4000,
    hoverpause: true,
    perView: 3,
    peek: { before: 0, after: 50 },
    gap: 28,
    breakpoints: {
      992: {
        perView: 2,
      },
      768: {
        perView: 1,
      },
    }
  });
  recommendationsSlider.mount();
}

function setup() {
  if(slideWrapper) {
    // collect all recommendation nodes
    let items = document.querySelectorAll(".vizy__recommendation");
    let i = 0;
    [].forEach.call(items, function (element) {
      element.parentElement.parentElement.removeChild(element.parentElement);
      itemsDOM.push(element.cloneNode(true));
      return;
    });

    // setup filter listeners
    [].forEach.call(filters, function (element) {
      // Commented out so that all cards are shown by default on page load without any filters being selected
      // filterIds.push(Number(element.value));

      element.addEventListener('change', () => {
        if(element.checked) {
          filterIds.push(Number(element.value));
        } else {
          filterIds.splice(filterIds.indexOf(Number(element.value)), 1);
        }

        updateFilters();
      }); 
    });

    slideWrapper.innerHTML = "<li>No Results</li>";

    createSlider();
    updateFilters();

    const filter = document.querySelector(".vizy__recommendations__topics__filter");
    
    filter.addEventListener('click', () => {
      filter.classList.toggle('vizy__recommendations__topics__filter--active');

      const row = document.querySelector(".vizy__recommendations__topics .row");

      if(filter.classList.contains('vizy__recommendations__topics__filter--active')) {
        row.classList.add('row--active');
      } else {
        row.classList.remove('row--active');
      }
    });
  }
}

function updateFilters() {
  let count = 0;

  // destroy slider
  recommendationsSlider.destroy();

  // remove all slides
  slideWrapper.innerHTML = "";
  let innerHTML = "";

  itemsDOM.forEach(function(item) {
    const myTopics = item.getAttribute('data-topics').split(',').map(function(item) {
      return Number(item.trim());
    }).filter(n => n);

    // The below is 'AND' logic..
    // Check if all item topics are in selected filter ids rather than the other way round
    // This allows for all items to be shown if no filters are selected
    // But also means when a user starts selecting filters only items that match all selected filters are shown
    if (filterIds.every(filterId => myTopics.includes(filterId))) {
      innerHTML += "<li>" + item.outerHTML + "</li>";
      count++;
    }
  });

  if(count === 0) {
    innerHTML = "<li>No Results</li>";
  }

  slideWrapper.innerHTML = innerHTML;
  createSlider();
  countElem.innerHTML = count + " results";
}

export default function() {
  setup();
}