const tables = document.querySelectorAll('.vizy__statistics .table');

function plotValues() {
  const fills = document.querySelectorAll('.vizy__statistics .table:not(.d-none) .fill');
  let highest = 0;

  [].forEach.call(fills, function (fill) {
    const num = Number(fill.dataset.value);
    if(num > highest) {
      highest = num;
    }
  });

  [].forEach.call(fills, function (fill) {
    fill.style.width = (fill.dataset.value / highest) * 100 + '%';
  });

}

function showHideTables(value) {

  const fills = document.querySelectorAll('.vizy__statistics .table:not(.d-none) .fill');
  [].forEach.call(fills, function (fill) {
    fill.style.width = 0 + '%';
  });

  [].forEach.call(tables, function (table) {

    if(table.id == value) {
      table.classList.remove('d-none');
    } else {
      table.classList.add('d-none');
    }

  });

  setTimeout(() => {
    plotValues();
  }, 0);

}

export default function() {
  const input = document.getElementById('stat-filter');

  if(input) {
    input.addEventListener('change', (e) => {
      const myValue = e.target.value;
      showHideTables(myValue);
    });

    const startingValue = input.value;
    showHideTables(startingValue);
  }

}