import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const state = document.getElementById("nav-toggle-state");

function handleNavLink(e) {
  const link = e.target.closest('.js-nav-link');
  if (!link) return;
  const href = link.getAttribute('href');
  if (href.includes('#')) {
    state.checked = false;
    document.body.classList.remove("nav-active");
  }
}

export default function () {
  state.addEventListener("change", (e) => {
    const active = e.currentTarget.checked;

    if (active) {
      document.body.classList.add("nav-active");
    } else {
      document.body.classList.remove("nav-active");
    }
  });

  const masthead = document.querySelector('.masthead');

  ScrollTrigger.create({
    trigger: masthead,
    start: 'top top',
    endTrigger: 'html', 
    end: 'bottom top',
    toggleClass: 'masthead--sticky',
    onToggle() {
      ScrollTrigger.refresh();
    }
  });

  const navLinks = document.querySelectorAll('.js-nav-link');
  navLinks.forEach(link => link.addEventListener('click', handleNavLink));
}
