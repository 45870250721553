import Glide from '@glidejs/glide'
import { goToTarget } from './globe';

export default function() {
  if (document.querySelectorAll('.global-slider, .vizy__globe__city').length) {

    var globeSlider = new Glide('.global-slider', {
      type: 'slider',
      autoplay: 4000,
      hoverpause: true,
      perView: 2,
      peek: { before: 0, after: 100 },
      gap: 28,
      breakpoints: {
        1439: {
          perView: 1,
        },
      }
    }).mount();

    const cities = document.querySelectorAll('.vizy__globe__city');

    globeSlider.on('run', (i) => {
      goToTarget(cities[globeSlider.index].id);
    })
  }
}